import { DairyRPPracticeCodeType } from 'insurance-quoter/types/graphql-types';

type CurrentPractice = DairyRPPracticeCodeType & {
	showClassIVOnly?: boolean;
	classRestrictions?: boolean;
	showClassIIIOnly?: boolean;
	inputDisabled?: boolean;
	showComponentClassIIIOnly?: boolean;
	showComponentClassIVOnly?: boolean;
	componentRestrictions?: boolean;
};

export { CurrentPractice };
